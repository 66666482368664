/* Prevent layout shift when scrollbar appears */
@supports (scrollbar-gutter: stable) {
  html {
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
  }
}

@supports not (scrollbar-gutter: stable) {
  html {
    overflow-y: scroll;
  }
}

/* Used for the loading overlay */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in forwards;
}

/* Used for required fields on forms */
.required-field::after {
  content: '\00a0*';
  color: #dc3545;
}