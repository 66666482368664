.react-select-container.is-invalid .react-select__control {
  border-color: #dc3545 !important;
}

.form-check-input[type=checkbox], .form-check-input[type=radio] {
  border-width: 2px;
  border-color: #909090;
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #dc3545 !important;
}